<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Objet / sous-objet" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewComponent 
          title="Détail d'un objet" 
          :fields="fields" 
        />
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewComponent from "@/components/ui/DetailViewComponent.vue";

import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import {
  addFieldWithSeveralValues,
  addFieldWithOneValue,
} from "@/components/ui/detail_view_component_tool.js";

// Le service qui n'en est pas un
// const _SERVICE_UNDEFINED = "undefined";
const _SERVICE_UNDEFINED = {id: "undefined", digitalName: "PAS DE SERVICE"};

export default {
  name: "DetailsTicketingObjectSuobject",
  components: { Workflow, TitleAndReturnComponent, DetailViewComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement du service. */
      service: null,
      serviceService: null,
      serviceApps : null,

      /** l'id de l'application dont l'objet dépend */
      appId: null,
      /** l'id du service dont l'objet dépend */
      serviceId: null,
      /**l'identifiant de l'entité à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      fields: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        // Récupération de l'application
        let app = await this.serviceApps.getById(this.appId);

        let serv = null;
        // Récupération du service
        if (this.serviceId == _SERVICE_UNDEFINED.id) {
          serv = _SERVICE_UNDEFINED;
        } else {
          serv = await this.serviceService.getServiceById(this.serviceId);
        }

        //Récupération de l'item par son id
        let entity = await this.service.getObjectById(this.appId, this.serviceId, this.entityId);
        // Récupération des sous-objects de l'object en cours
        entity.subobject = await this.service.getAllSubobjects(this.appId, this.serviceId, this.entityId);


        addFieldWithOneValue(this.fields, "Application", app.label || '-', true);

        addFieldWithOneValue(this.fields, "Service", serv.digitalName || '-', true);

        addFieldWithOneValue(this.fields, "Label", entity.label || '-', true);

        
        if (entity.subobject.length > 0) { 
          addFieldWithSeveralValues(
            this.fields,
            "sous-objets",
            entity.subobject.map((r) => r.label),
            false
          );
        } else {
          addFieldWithOneValue(
            this.fields,
            "sous-objets",
            "-",
            false
          );
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.service = new TicketingObjectSubobjectService(this.$api.getTicketingObjectSubobject());
    this.serviceService = new ServicesService(this.$api);
    this.serviceApps = new ApplicationService(this.$api.getApplicationApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }
    // Récupération des paramètres de la route de l'objet
    if (this.$route.query) {
      if (this.$route.query.app) {
        this.appId = this.$route.query.app;
      }
      if (this.$route.query.service) {
        this.serviceId = this.$route.query.service;
      }
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>